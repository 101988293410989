/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.blas {
    .loginBg {
        background-image: url(assets/loginBlas.jpeg);
    }
}

.choir {
    .loginBg {
        background-image: url(assets/loginChoir.jpeg);
    }
}

.marginBottom {
    margin-bottom: 4rem;
}

.attDateModal {
    --width: 290px;
    --height: 382px;
    --border-radius: 8px;
}

ion-modal ion-datetime {
    height: 382px;
}
